a.btn {
	background-color: $ucla-blue;
	color: $white;
	padding: 12px 16px;
	cursor: default;
	font-size: 16px;
	text-align: center;
	display: inline-block;
	border: none;

	&.secondary {
		background-color: $eerie-black-lighter;
	}
}

a.btn > .fa + span {
	margin-left: 6px;
}
a.btn > span + .fa {
	margin-left: 6px;
}

a.btn:hover {
	color: $white;
	background-color: lighten($ucla-blue, 10%);
}
