#TableOfContents {
	padding-left: 12px;
	margin-bottom: 16px;
	li {
		font-family: $font-header;
		font-weight: 500;
		margin-bottom: 4px;
	}

	ul {
		padding-left: 12px;
		margin-top: 8px;
	}

	> ul > li > ul > li > ul {
		display: none;
	}

	a {
		font-size: 95%;
		display: inline-block;
		line-height: 1.4;
	}

	a.active {
		color: $mojo-orange;
	}
}