.posts {
	li {
		overflow: hidden;
		padding-bottom: 16px;
		margin-bottom: 26px;
		border-bottom: $pewter-blue-light 1px solid;
	}
	li:last-child {
		border-bottom: none;
	}
}

.post-image {
	float: left;
	width: 150px;
	height: 120px;
	overflow: hidden;

	img {
		display:block;
		width: 100%;
		margin-top: -10%;
	}
}

.post-description {
	margin-left: 166px;
	h2 {
		margin-top: 0px;
	}
}

.post-details {
	color: $pewter-blue;
}



@media screen and (max-width: 760px) {
	.post-image {
		width: 80px;
		height: 80px;
		margin-right: 10px;

		img {
			display:block;
			width: 100%;
		}
	}

	.post-description {
		margin-left: 0px;
		h2 {
			margin-top: 0px;
			font-size: 18px;
		}
	}
}