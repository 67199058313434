.banner {
	background-color: $eerie-black-light;

	h1, h2 {
		color: $pewter-blue;
		font-weight: 400;
		margin: 0 0 12px 0;
		line-height: 42px;
	}

	h1 {
		font-size: 42px;
	}

	h2 {
		font-family: $font-text;
		font-size: 38px;
	}

	h3 {
		font-size: 28px;
		color: $ucla-blue;
		font-weight: 400;
		margin-bottom: 12px;
	}

	p {
		color: $white;
	}
}

.banner-content {
	padding: 40px 40px;
	position: relative;
	max-width: 1024px;
	margin: 0 auto;
}

.jumbo {
	margin: 40px 0 40px 0;
}

.jumbo--links {
	margin-top: 24px;
	word-spacing: 24px;
	line-height: 68px;

	a.btn {
		word-spacing: initial;
		line-height: 24px;
		width: 160px;
		padding: 12px 32px;
		display: inline-block;
	}
}

.jumbo--logo-img {
	width: 220px;
	height: 220px;
	vertical-align: middle;
	margin: 0 auto;
}

.jumbo--logo {
	display: none;
	font-family: $font-header;
}

.highlights a {
	color: $pewter-blue;

	&:hover {
		color: $pewter-blue-light;
	}
}


@media screen and (max-width: 1048px) {
	.jumbo {
		margin: 0 0 10px 0;
		text-align: center;
	}

	.jumbo--logo-img {
		width: 142px;
		height: 142px;
	}

	.jumbo--logo {
		display: block;
		margin: 20px 0 48px 0;
		font-size: 40px
	}

	.banner {
		h1, h2 {
			line-height: 32px;
			font-size: 28px;
		}
	}

	.highlights {
		margin: 0 auto;
		max-width: 400px;
		text-align: center;
	}
}

@media screen and (max-width: 900px) {
	.banner-content {
		padding: 30px 30px;
	}
}

@media screen and (max-width: 760px) {
	.banner-content {
		padding: 24px 12px;
	}
}
