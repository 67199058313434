

.footer-padding {
	width: 100%;
	height: 186px;
}

footer {
	position: absolute;
	bottom: 0;
	width: 100%;
	height: 186px;
	background: $eerie-black-lightest;
	color: $pewter-blue;

	.footer-container {
		position: relative;
		padding: 40px 40px 0 40px;
		max-width: 1260px;
		margin: 0 auto;
	}

	.iconlinks > a {
		font-size: 32px;
		color: $pewter-blue;
		cursor: default;

		&:hover {
			color: $pewter-blue-light;
		}
	}

	.iconlinks {
		word-spacing: 18px;
	}

	a {
		color: $pewter-blue-light;

		&:hover {
			color: $portland-orange;
		}
	}
}

.no-section-nav {
	.footer-padding {
		height: 88px;
	}

	footer {
		height: 88px;

		.footer-container {
			padding: 20px 40px 0 40px;
		}

		.iconlinks {
			display: inline-block;
			line-height: 48px;
		}
		.license {
			float: right;
			text-align: right;
		}
	}
}

.frontpage {
	footer {
		.footer-container {
			text-align: center;
		}

		.iconlinks {
			margin-bottom: 12px;
		}
	}
}

@media screen and (max-width: 760px) {
	.footer-padding {
		height: 136px;
	}

	footer {
		height: 136px;

		.footer-container {
			padding: 20px 12px 0 12px;
		}
	}

	.no-section-nav {
		.footer-padding {
			height: 72px;
		}

		footer {
			height: 72px;

			.footer-container {
				padding: 12px 20px 0 20px;
			}

			.iconlinks {
				line-height: 46px;
			}
			.license {
				font-size: 94%;
			}
		}
	}

}
