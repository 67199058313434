/**
 * Menu toggle button
 * Curtesy to Sami Keijonen
 * https://codepen.io/samikeijonen/pen/jqvxdL
 */

.menu-toggle {
	border: none;
	border-radius: 0;
	background: none;
	color: $pewter-blue;
	position: fixed;
	z-index: 110;
	top: 10px;
	left: 20px;
	cursor: pointer;
	padding: 8px 10px;
	transition: all 0.25s ease-in-out;
	display: none;

	&:focus {
		outline: none;
	}
}

/* Menu toggle styles. */
.icon-menu-toggle {
	display: inline-block;
	width: 24px;
	height: 24px;
}

.icon-menu-toggle {
	.svg-menu-open {
		opacity: 1;
		fill: $pewter-blue;
		transition: opacity 0.3s ease-in-out;
	}
	.svg-menu-close {
		opacity: 0;
		fill: $eerie-black;
		transition: opacity 0.3s ease-in-out;
	}
}

.sidebar-opened .icon-menu-toggle {
	.svg-menu-open {
		opacity: 0;
	}

	.svg-menu-close {
		opacity: 1;
	}
}

/* Text meant only for screen readers. */
.screen-reader-text {
	clip: rect(1px, 1px, 1px, 1px);
	position: absolute !important;
	height: 1px;
	width: 1px;
	overflow: hidden;
}

@media screen and (max-width: 900px) {
	.with-section-nav {
		.menu-toggle {
			display: block;
		}
	}
}

@media screen and (max-width: 760px) {
	.menu-toggle {
		display: block;
		top: 2px;
		left: 6px;
	}

	.icon-menu-toggle {
		width: 20px;
		height: 20px;
	}
}