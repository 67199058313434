/* Colors */
$white: #fff;
$ucla-blue: #5a5d93;
$eerie-black: #161925;
$eerie-black-light: lighten($eerie-black, 2%);
$eerie-black-lighter: lighten($eerie-black, 6%);
$eerie-black-lightest: lighten($eerie-black, 10%);
$pewter-blue: #91aec1;
$pewter-blue-light: #ebf1f4;
$mojo-orange: #bf573a;
$portland-orange: #f46036;
$russian-violet: #372248;

/* Font families */
$font-text: 'Open Sans', sans-serif;
$font-header: 'Encode Sans', sans-serif;
$font-code: Consolas, Monaco, 'Andale Mono', 'Ubuntu Mono', monospace;