.card {
	background: $pewter-blue-light;
	margin-bottom: 16px;

	a.btn {
		width: 100%;
		display: block;
	}
}

.card--top {
	padding: 16px 24px 8px 24px;
}

.card--title {
	display: inline-block;
	font-size: 18px;
	margin: 0;
}

.card--subtitle {
	display: block;
}

.card--img {
	float: left;
	vertical-align: middle;
	margin-right: 20px;

	> svg {
		width: 44px;
		height: 44px;
	}
}

.card--body {
	padding: 8px 24px 16px 24px
}
