.row {
	width: 100%;

	&:before, &:after {
		display: table;
		content: " ";
	}

	&.row:after { clear: both; }
}

.column-2, .column-3 {
	margin: 0;
	float: left;
	min-height: 1px;
	position: relative;
	display: block;
	margin-left: 3.5%;

	&:first-child {
		margin-left: 0;
	}
}

.column-2 {
	width: 48.25%;
}

.column-3 {
	width: 31%;
}

.column-3.span-2 {
	width: 65.5%;
}

@media screen and (max-width: 1048px) {
	.column-2, .column-3, .column-3.span-2 {
		margin-left: 0;
		width: 100%;
	}
}
