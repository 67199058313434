body {
	font-family: $font-text;
	font-size: 1em;
	color: $eerie-black;
	background-color: $white;
	margin: 0;
	line-height: 24px;
	font-size: 11pt;
}

* {
	box-sizing: border-box;
}

main {
	display: block;
}

h1, h2, h3, h4, h5 {
	font-family: $font-header;
	margin: 32px 0 11px;
}

p {
	margin-bottom: 12px;
}

h1 {
	color: $ucla-blue;
	font-size: 1.6em;
	font-weight: 700;
	margin: 20px 0 24px;
}

h2 {
	color: $ucla-blue;
	font-size: 1.4em;
	font-weight: 700;
}

h3 {
	color: $ucla-blue;
	font-weight: 700;
	margin-bottom: 6px;
	margin-top: 20px;
}

em {
	font-style: italic;
}

strong {
	font-weight: 600;
}

a {
	text-decoration: none;
	color: inherit;
}

.main {
	position: relative;
	min-height: 100vh;
	padding-top: 60px;
}

#sidebar {
	position: fixed;
	z-index: 10;
	top: 60px;
	left: 0;
	bottom: 0;
	max-width: 90%;
	width: calc(340px + (100% - 1260px) / 2);
	background: $pewter-blue;
	display: none;
}

.sidebar--inner {
	width: 340px;
	height: 100%;
	padding: 40px 20px 60px 40px;
	overflow-x: hidden;
	overflow-y: auto;
	margin-left: auto;
}

.sidebar--divider {
	width: calc(100% - 20px);
	height: 2px;
	margin-top: 32px;
	background-color: $eerie-black;
}

.main-nav {
	display: none;
}

.sidebar--nav > li {
	margin-top: 1em;
}

.sidebar--nav-link {
	font-family: $font-header;
	font-weight: 500;

	&.active {
		font-weight: 700;
		color: $mojo-orange;
	}

	&:hover {
		border-bottom: 2px solid $mojo-orange;
	}

	sup {
		vertical-align: top;
		position: relative;
		top: -0.5em;
		left: 3px;
		font-size: 60%;
		text-transform: uppercase;
		margin-right: -5px;
		color: $mojo-orange;
		text-shadow: 0 1px $eerie-black;
	}
}

.sidebar--nav-child > li {
	margin-top: 0.5em;
}

.sidebar--nav-child-link {
	font-family: $font-header;
	font-weight: 500;

	&.active {
		color: $mojo-orange;
	}

	&:hover {
		border-bottom: 2px solid $mojo-orange;
	}
}

.content {
	padding: 40px 40px;
	position: relative;
	max-width: 1260px;
	margin: 0 auto;

	a {
		color: darken($pewter-blue, 20%);
		&:hover {
			color: $pewter-blue;
		}
	}

	h1:before,
	h2:before,
	h3:before {
		content: "";
		display: block;
		margin-top: -80px;
		height: 80px;
		visibility: hidden;
	}

	&.slim  {
		max-width: 920px;
	}
}

.with-section-nav {
	#sidebar {
		display: block;
	}

	.content {
		width: 920px;
		margin-left: calc(340px + (100% - 1260px) / 2);
		margin-right: auto;
	}
}

@media screen and (max-width: 1260px) {
	.with-section-nav {
		.content {
			width: auto;
			margin-left: 340px;
		}
	}

	#sidebar {
		width: 340px;
	}
}

@media screen and (max-width: 900px) {
	#sidebar {
		z-index: 105;
		height: 100%;
		top: 0;
		left: 0;
		box-shadow: 0 0 8px rgba(0,0,0,0.2);
		transition: all 0.4s cubic-bezier(0.4, 0, 0, 1);
		-webkit-transform: translate(-110%, 0);
		transform: translate(-110%, 0);
	}

	.sidebar-opened #sidebar {
		-webkit-transform: none;
		transform: none;
	}

	.content {
		padding: 30px 30px;
	}

	.with-section-nav {
		.content {
			margin-left: 0;
		}
	}
}

@media screen and (max-width: 760px) {
	.main {
		padding-top: 40px;
	}

	#sidebar {
		display: block;
	}

	.sidebar--inner {
		padding: 30px 16px 40px 48px;
	}

	.content {
		padding: 12px 12px;

		h1:before,
		h2:before,
		h3:before {
			margin-top: -60px;
			height: 60px;
		}
	}

	.main-nav {
		display: block;
	}
}

@media print {
	#sidebar {
		display: none;
	}
}
