

/* Layout */
.header-mobile {
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 40px;
	z-index: 100;
	display: none;

	background-color: $eerie-black;
	box-shadow: 0 0 2px rgba(0,0,0,0.25);

	> .logo {
		position: absolute;
		top: 5px;
		left: 50%;
		transform: translateX(-50%);
		font-size: 110%;
		line-height: 28px;
	}

	.logo--img {
		width: 30px;
		height: 30px;
	}
}

.header {
	height: 60px;
	z-index: 100;

	position: fixed;
	width: 100%;
	top: 0;

	background-color: $eerie-black;
	color: $pewter-blue;
	font-family: $font-header;

	.logo {
		line-height: 34px;
		font-size: 24px;
	}

	.logo--img {
		vertical-align: middle;
		margin-right: 14px;
	}
}

.header--inner {
	max-width: 1260px;
	margin: 0 auto;
	position: relative;
	padding: 10px 40px;
}

.header--nav {
	float: right;
	height: 40px;
	line-height: 40px;

	> li {
		display: inline-block;
		position: relative;
		margin-left: 12px;
	}

	sup {
		vertical-align: top;
		position: relative;
		top: -0.5em;
		left: 3px;
		font-size: 60%;
		text-transform: uppercase;
		margin-right: -5px;
		color: $portland-orange;
	}
}

.header--nav-link {
	font-family: $font-header;
	font-weight: 500;
	padding: 4px;

	&.active {
		font-weight: 700;
		color: $portland-orange;
	}

	&:hover {
		border-bottom: 2px solid $portland-orange;
	}
}

.logo--res {
	font-weight: 700;
	color: $pewter-blue;
}
.logo--gate {
	color: $ucla-blue;
}

@media screen and (max-width: 900px) {
	.header--inner {
		padding: 10px 30px;
	}

	.with-section-nav {
		.header--inner {
			padding-left: 84px;
		}
	}
}

@media screen and (max-width: 760px) {
	.header-mobile {
		display: block;
	}

	.header {
		display: none;
	}
}