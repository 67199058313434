.content header {
	margin-bottom: 36px;
}

.content header > h1 {
	color: $eerie-black;
	margin-bottom: 20px;
	margin-top: 16px;
	font-size: 48px;
	line-height: 53px;
}

.content header .post--subtitle {
	margin-top: 20px;
	font-style: italic;
}

.guide-links {
	margin-top: 32px;
	height: 48px;
}

.next {
	float: right;
	i {
		padding-left: 4px;
	}
}

.prev i {
	padding-right: 4px;
}

.indent {
	padding-left: 2em;
}

.clear {
	clear: both;
	height: 1px;
	width: 100%;
}

.textbody {

	a {
		background-color: rgba( $pewter-blue, 0.15 );
		border-bottom: 1px solid rgba( $pewter-blue, 0.75 );
		color: $ucla-blue;
		padding: 0 4px;

		&:hover {
			background-color: rgba( $pewter-blue, 0.40 );
			color: $eerie-black;
			border-bottom-color: $mojo-orange;
		}

		code {
			background-color: transparent;
			padding: 0;
		}

		&.img-link {

			display: inline-block;
			border-color: rgba( $pewter-blue, 0.15 );
			padding: 6px 12px;
			margin-top: 6px;

			&:hover {
				background-color: rgba( $pewter-blue, 0.40 );
				border-bottom-color: $mojo-orange;
			}
		}
	}

	sup {
		vertical-align: top;
		position: relative;
		top: -0.5em;
		font-size: 80%;
		font-weight: normal;
	}

	sup a, .footnote-return {
		background-color: inherit;
		border-bottom: none;

		&:hover {
			border-bottom: 1px solid $mojo-orange;
		}
	}

	h1, h2 {
		margin-top: 34px;
	}

	img {
		display: block;
		max-width: 100%;
		height: auto;
	}

	code {
		font-family: $font-code;
		padding: 0 4px;
		white-space: pre-wrap;
		background-color: rgba( $eerie-black, 0.10 );
	}

	pre code {
		background-color: transparent;
		padding: 0;
		white-space: inherit;
	}

	ul {
		list-style: disc;
	}

	ol {
		list-style: decimal;
	}

	ul, ol {
		margin: 1em 0;
		padding-left: 40px;

		> li {
			padding-left: 4px;
			margin-bottom: 2px;
		}
	}

	table {
		display: block;
		overflow-x: auto;
		white-space: nowrap;
	}

	thead {
		border-bottom: solid 1px $eerie-black;
	}

	th {
		font-weight: 700;
		font-family: $font-header;
	}

	th, td {
		padding: 4px 8px;
		text-align: left;
	}

	tbody > tr {
		border-bottom: solid 1px $pewter-blue-light;

		&:last-child {
			border-bottom: none;
		}
	}

	hr {
		border: none;
		border-bottom: solid 1px $pewter-blue-light;
		margin-top: 38px;
		margin-bottom: 34px;
	}

	blockquote, q {
		border-left: solid 8px $portland-orange;
		padding: 8px 12px 0 12px;
		overflow-y: hidden;
		background: rgba( $portland-orange, 0.25 );
		margin: 16px 0;
	}

	figure {
		margin: 24px 0;
		text-align: center;
		> * {
			margin: auto;
			margin-top: 12px;
		}
	}

	figcaption {
		font-style: italic;
	}
}

@media screen and (max-width: 760px) {
	.content header > h1 {
		font-size: 28px;
		line-height: 32px;
	}
}
