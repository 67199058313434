/* Background */ .highlight > .chroma {
	color: #f8f8f2;
	background-color: $eerie-black;
	font-family: $font-code;
	line-height: 1.5;

	-moz-tab-size: 4;
	-o-tab-size: 4;
	tab-size: 4;

	-webkit-hyphens: none;
	-moz-hyphens: none;
	-ms-hyphens: none;
	hyphens: none;

	padding: 1em;
	margin: 12px 0;
	overflow: auto;
	border-radius: 4px;
	border: 1px solid #ccc;
	box-sizing: border-box;
}

.chroma {
	background-color: inherit;
}

/* LineHighlight */ .chroma .hl {
	background-color: $russian-violet;
}
/* LineTable */ .chroma .lntable {
	overflow: initial;
}

.chroma {
	.kn, .nt, .o, .ow {
		color: $pewter-blue;
	}
}